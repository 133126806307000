import { ClaimStages } from '../domain/types'
import { RealBenefitLodgementConfig, Obligations, ProductConfigService, PseudoTopLevelBenefitConfig } from './ProductConfigService'

const mandatoryClaim: Pick<RealBenefitLodgementConfig, 'CLAIM' | 'COVER_CHECK'> = {[ClaimStages.CLAIM]: {obligation: Obligations.MANDATORY, windowInWeeks: 16}}
const encouragedCoverCheck: Pick<RealBenefitLodgementConfig, 'CLAIM' | 'COVER_CHECK'> = {[ClaimStages.COVER_CHECK]: {obligation: Obligations.ENCOURAGED}}
const optionalCoverCheck: Pick<RealBenefitLodgementConfig, 'CLAIM' | 'COVER_CHECK'> = {[ClaimStages.COVER_CHECK]: {obligation: Obligations.OPTIONAL}}

const mandatoryClaimLodgedOnParent = {...mandatoryClaim}
mandatoryClaimLodgedOnParent.CLAIM.proxyLevel = 'PARENT'

const encouragedCoverCheckLodgedOnParent = {...encouragedCoverCheck}
encouragedCoverCheckLodgedOnParent.COVER_CHECK.proxyLevel = 'PARENT'

const mentalHealth: PseudoTopLevelBenefitConfig = {
    type: 'MENTAL_HEALTH',
    displayName: 'Mental health',
    pseudo: 'PARENT',
    children: [
        {
            type: 'MENTAL_HEALTH_IN_PATIENT',
            displayName: 'Mental health (in patient)',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            ...mandatoryClaimLodgedOnParent,
            ...encouragedCoverCheckLodgedOnParent
        },
        {
            type: 'MENTAL_HEALTH_OUT_PATIENT',
            displayName: 'Mental health (out patient)',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            ...mandatoryClaimLodgedOnParent,
            ...encouragedCoverCheckLodgedOnParent
        }
    ]
}

export const peachyProductConfigService = new ProductConfigService({
    benefits: [
        {
            type: 'DENTAL',
            displayName: 'Dental',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaim,
                ...optionalCoverCheck
            }
        },
        {
            type: 'OPTICAL',
            displayName: 'Optical',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaim,
                ...optionalCoverCheck
            }
        },
        {
            type: 'THERAPIES',
            displayName: 'Therapies',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaim,
                ...encouragedCoverCheck
            },
            drivesFeatures: ['BOOK_PHYSIO']
        },
        {
            ...mentalHealth
        },
        {
            type: 'CONSULTATIONS_AND_DIAGNOSTICS',
            displayName: 'Consultations & diagnostics',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        },
        {
            type: 'HOSPITAL_CARE',
            displayName: 'Hospital care',
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        },
        {
            type: 'VIRTUAL_GP',
            displayName: 'Virtual GP',
            offerAsUpsell: true,
            drivesFeatures: ['BOOK_VGP']
        },
        {
            type: 'ADVICE_AND_COUNSELLING',
            displayName: 'Advice and counselling',
            offerAsUpsell: false,
            drivesFeatures: ['ADVICE_AND_COUNSELLING']
        }
    ]
})
