import { ConsoleLogger, enumerate, FilterPredicate, mapBy, Optional, Redefine } from '@peachy/utility-kit-pure'
import { uniqBy, values } from 'lodash-es'
import { ClaimStage, ClaimStages, BenefitType, BenefitTypeable, AppFeature } from '../domain/types'
import { benefitTypeOf } from '../domain/utils'
import * as PlanConfigJson from '@punnet/product-pure'
import { Plan, PlanConfig } from '@punnet/product-client'
import { filter, isEmpty } from 'lodash-es'
import { QuestionId } from '../domain/enquiry/types'

export const Obligations = enumerate(['MANDATORY', 'ENCOURAGED', 'OPTIONAL'] as const)
export type Obligation = keyof typeof Obligations

export type BenefitLimit = Limit<'PENCE' | 'USES'>
export type BenefitLimitConfig = Pick<BenefitLimit, 'unit'>

type ProxyLodgementLevel = 'PARENT' | 'CHILD'

export type RealBenefitClaimLodgementConfig = {
    windowInWeeks: number 
    obligation?: Obligation
    questions?: QuestionId[]
    proxyLevel?: ProxyLodgementLevel
}

export type RealBenefitCoverCheckLodgementConfig = {
    obligation?: Obligation
    proxyLevel?: ProxyLodgementLevel
}

export type RealBenefitLodgementConfig = {
    [ClaimStages.COVER_CHECK]?: RealBenefitCoverCheckLodgementConfig
    [ClaimStages.CLAIM]?: RealBenefitClaimLodgementConfig
}

type PseudoChildBenefitLodgementConfig = {
    [ClaimStages.COVER_CHECK]?: { questions?: QuestionId[] }
    [ClaimStages.CLAIM]?: { questions?: QuestionId[] }
}
type Limit<Unit extends 'PENCE' | 'USES'> = {
    value: number
    unit: Unit
}

export type CashLimited<Thing extends {limit?: Limit<any>}> = Redefine<Thing, 'limit', Readonly<Limit<'PENCE'>>>
export type UsesLimited<Thing extends {limit?: Limit<any>}> = Redefine<Thing, 'limit', Readonly<Limit<'USES'>>>
export type Limited<Thing> = CashLimited<Thing> | UsesLimited<Thing>


type CommonBenefitConfig = {
    type: BenefitType
    displayName: string
} 

export type RealTopLevelBenefitConfig = CommonBenefitConfig & {
    pseudo?: undefined,
    offerAsUpsell?: boolean
    limit?: BenefitLimitConfig
    lodgement?: RealBenefitLodgementConfig
    drivesFeatures?: AppFeature[]
    children?: PseudoChildBenefitConfig[]
}

type RealChildBenefitConfig = Omit<RealTopLevelBenefitConfig, 'children'> & {
    children?: undefined
}

export type PseudoTopLevelBenefitConfig = CommonBenefitConfig & {
    pseudo: 'PARENT',
    lodgement?: undefined
    children: RealChildBenefitConfig[]
}
    
type PseudoChildBenefitConfig = CommonBenefitConfig & {
    pseudo: 'CHILD',
    lodgement?: PseudoChildBenefitLodgementConfig,
    children?: undefined
}

export type BenefitConfig = CommonBenefitConfig & (
    RealTopLevelBenefitConfig | RealChildBenefitConfig | PseudoTopLevelBenefitConfig | PseudoChildBenefitConfig
)

export type ProductsConfig = {
    benefits: BenefitConfig[]
    // this is only optional because Peachy Individual config hasn't been refactored/created yet so can't be supplied in that context.  Nothing in the peachy app requires this config yet though either
    planConfig?: PlanConfigJson.Config
}

type NonPseudoBenefitConfig = RealTopLevelBenefitConfig | RealChildBenefitConfig
type PseudoBenefitConfig = PseudoTopLevelBenefitConfig | PseudoChildBenefitConfig

/**
 * There is a bit of a disjointed overlap (oxymoron?) in plan/product config between the mobile app (MA), the backend (BE), and the web front end WFE.
 * This service gets contructed with all these seperate bits of config and aims to pull some of it together.  It's not perfect, and still remains disjointed.
 * Main concerns: 
 *     * there is no config at all for the peachy individual product offering in the BE or WFE, it does exist for MA though.
 *     * MA config represents a two level hierarchy of benefits (see MENTAL_HEALTH) where we can hang properties at each level. BE & WEF config allows a benefit to define "sub types" but not to be able to hang properties off of them (display name etc...)
 * This service was initially created for MA config and as such most methods expose the MA config model.  Methods that expose the other config model should be commented as doing so
 */
export class ProductConfigService {

    readonly planConfig: PlanConfig
    readonly allBenefitsByType: Record<BenefitType, BenefitConfig>
    readonly nonPseudoBenefitsByType: Record<BenefitType, NonPseudoBenefitConfig>
    readonly pseudoBenefitsByType: Record<BenefitType, PseudoBenefitConfig>

    constructor(config: ProductsConfig, private logger = new ConsoleLogger()) {
        this.planConfig = config.planConfig ? new PlanConfig(config.planConfig) : undefined
        
        const flattenedBenefits = config.benefits.flatMap(it => [it, ...(it.children ?? [])])
        this.allBenefitsByType = mapBy(flattenedBenefits, it => it.type)
        this.pseudoBenefitsByType = mapBy(flattenedBenefits.filter(isPseudo), it => it.type)
        this.nonPseudoBenefitsByType = mapBy(flattenedBenefits.filter(isNonPseudo), it => it.type)
        
        // must be called last as relies on construction setup as well as raw config
        this.validateTheConfig(config.benefits)
    }

    getBenefitConfigFor(benefitType: BenefitType) {
        return this.allBenefitsByType[benefitType]
    }

    getNonPseudoBenefitConfigFor(benefitType: BenefitType) {
        return this.nonPseudoBenefitsByType[benefitType]
    }

    getParentBenefitConfigFor(benefitType: BenefitType) {
        // if it has kids then we know it has to be a top level on so force/cast the return type
        return values(this.allBenefitsByType).find(benefit => 
            benefit.children?.some(it => it.type === benefitType)
        ) as Optional<RealTopLevelBenefitConfig | PseudoTopLevelBenefitConfig>
    }

    getClaimableBenefitTypesAtTheLowestLevel() {
        const claimableRealBenefits = this.filterNonPseudoBenefitConfigs(it => !!it.lodgement?.CLAIM?.obligation)
        return claimableRealBenefits.flatMap(it => {
            return isEmpty(it.children) ? [it.type] : it.children.map(child => child.type)
        })
    }

    getCoverCheckableBenefitTypesAtTheLowestLevel() {
        const checkableRealBenefits = this.filterNonPseudoBenefitConfigs(it => !!it.lodgement?.COVER_CHECK?.obligation)
        return checkableRealBenefits.flatMap(it => {
            return isEmpty(it.children) ? [it.type] : it.children.map(child => child.type)
        })
    }
    
    anyBenefitsExistWithAnyObligationToCoverCheck() {
        return !isEmpty(this.getCoverCheckableBenefitTypesAtTheLowestLevel())
    }

    filterNonPseudoBenefitConfigs<T extends NonPseudoBenefitConfig>(filterArgs?: FilterPredicate<T>): T[]  {
        const all = values(this.nonPseudoBenefitsByType)
        return (filterArgs ? filter(all, filterArgs) : all) as T[]
    }

    getUpsellableBenefitTypes() {
        return this.filterNonPseudoBenefitConfigs({offerAsUpsell: true}).map(it => it.type)
    }

    getFeatureDrivingBenefitConfigs() {
        return this.filterNonPseudoBenefitConfigs(it => !isEmpty(it.drivesFeatures))
    }

    /**
     * NOTE: this implementation does not support inheritance from parent.  YAGNI
     */
    getClaimLodgementQuestionIdsFor(benefitType: BenefitType) {
        return this.getBenefitConfigFor(benefitType)?.lodgement?.CLAIM?.questions ?? []
    }

    /**
     * will look back up to the parent for the window if not present on the passed benefit type's config directly (i.e. if it's a pseudo child)
     * it doesn't make sense to look down at the children because they could have a mix of windows
     */
    getClaimLodgementWindowInWeeksForItOrItsParent(benefitType: BenefitType) {
        const config = this.getNonPseudoBenefitConfigFor(benefitType)
        const parentConfig = this.getParentBenefitConfigFor(benefitType)
        return config?.lodgement?.CLAIM?.windowInWeeks ?? parentConfig?.lodgement?.CLAIM?.windowInWeeks
    }

    /**
     * will look at both the parent and child benefit config for any obligation at any level.  
     * i.e. a pseudo parent can have children with obligations to raise,  in which case return true
     * or a pseudo child could have a parent with an obligation to raise, in which case return true
     */
    shouldRaiseClaimsActivityProcessingTicketFor(benefitType: BenefitType, claimStage: ClaimStage) {
        // obligations only live at the non-pseudo level, hence all the filtering
        const lodgementConfigs: RealBenefitLodgementConfig[] = []
        const config = this.getBenefitConfigFor(benefitType)
        const parentConfig = this.getParentBenefitConfigFor(benefitType)
        if (isNonPseudo(config)) {
            lodgementConfigs.push(config.lodgement)
        }
        if (parentConfig && isNonPseudo(parentConfig)) {
            lodgementConfigs.push(parentConfig.lodgement)
        }
        config.children?.forEach(it => {
            if (isNonPseudo(it)) {
                lodgementConfigs.push(it.lodgement)
            }
        })
        const obligations = lodgementConfigs.map(it => it?.[claimStage]?.obligation)
        return anyObligationExists(...obligations)
    }

    getBenefitDisplayName(thing: BenefitTypeable) {
        return this.getBenefitConfigFor(benefitTypeOf(thing))?.displayName
    }
    
    getLodgementLevelBenefitConfigsFor(claimStage: ClaimStage, givenNonPseudoBenefitTypes: BenefitType[]) {

        const obligatedNonPseudoConfigs = this.filterNonPseudoBenefitConfigs(it => 
            givenNonPseudoBenefitTypes.includes(it.type) && 
            anyObligationExists(it.lodgement?.[claimStage]?.obligation)
        )

        const lodgementLevelConfigs = obligatedNonPseudoConfigs.flatMap(it => {
            const configs: BenefitConfig[] = []
            if (it.lodgement?.[claimStage]?.proxyLevel === 'CHILD') {
                configs.push(...(it.children ?? []))
            } else if (it.lodgement?.[claimStage]?.proxyLevel === 'PARENT') {
                configs.push(this.getParentBenefitConfigFor(it.type))
            } else {
                configs.push(it)
            }
            return configs
        })

        return uniqBy(lodgementLevelConfigs, it => it.type)
    }

    /**
     * @returns non mobile app config model plans, ie. the WFE and BE model is exposed here so be careful of expectations around benefit hierarchy properties
     */
    getPackagedPlans(predicate?: FilterPredicate<Plan>) {
        const plans = this.planConfig?.getAllPlans() ?? []
        return predicate ? filter(plans, predicate) : plans
    }

    getPackagedPlanUpgradeOptions({currentPackagedPlanId}: {currentPackagedPlanId: string}) {
        
        const allPlans = this.getPackagedPlans()
        const upsellableBenefitTypes = this.getUpsellableBenefitTypes()
        
        const plans = filterNonUpsellableBenefitsFrom(allPlans, upsellableBenefitTypes)

        const currentPackagedPlanIndex = plans.findIndex(it => it.id === currentPackagedPlanId)
        const currentPackagedPlan = plans[currentPackagedPlanIndex]
        const planUpgradeOptions = currentPackagedPlan ? plans.slice(currentPackagedPlanIndex + 1) : plans

        return {
            currentPackagedPlan,
            planUpgradeOptions
        }
    }

    private validateTheConfig(rawBenefitsConfig: BenefitConfig[]) {        
        // Validate unique benefit types
        const flatBenefits = rawBenefitsConfig.flatMap(it => [it, ...(it.children ?? [])])
        const benefitTypes = flatBenefits.map(it => it.type)
        const uniqueBenefitTypes = new Set(benefitTypes)
        if (uniqueBenefitTypes.size !== benefitTypes.length) {
            throw new Error('Duplicate benefit types found in config.  Types must be unique across all benefits including pseudo benefits and parent/child')
        }

        // Validate parent-child relationships
        flatBenefits.forEach(benefit => {
            const childrenWithChildren = benefit.children?.filter(it => !isEmpty(it.children))
            if (!isEmpty(childrenWithChildren)) {
                throw new Error(`${benefit.type} has children: ${childrenWithChildren} with children.  Only one level of nesting is supported`)
            }

            benefit.children?.forEach(child => {
                if (benefit.pseudo && child.pseudo) {
                    throw new Error(`child ${child.type} of parent ${benefit.type} are both pseudo.  one or the other must be a real benefit`)
                }
            })

            if (benefit.pseudo === 'PARENT' && (!benefit.children?.length || benefit.children.some(c => !!c.pseudo))) {
                throw new Error(`Pseudo PARENT benefit ${benefit.type} must have real (non-speudo) children benefits`)
            }
        })
        
    }
}

function filterNonUpsellableBenefitsFrom(plans: Plan[], upsellableBenefitTypes: string[]) {
    return plans.map(plan => ({
        ...plan,
        benefits: plan.benefits.filter(it => upsellableBenefitTypes.includes(it.id))
    }))
}

function isPseudo(benefitConfig: BenefitConfig) : benefitConfig is (PseudoTopLevelBenefitConfig | PseudoChildBenefitConfig) {
    return !!benefitConfig.pseudo
}

function isNonPseudo(benefitConfig: BenefitConfig) : benefitConfig is (RealTopLevelBenefitConfig | RealChildBenefitConfig) {
    return !isPseudo(benefitConfig)
}

function anyObligationExists(...obligations: Obligation[]) {
    return obligations.some(it => ([Obligations.MANDATORY, Obligations.ENCOURAGED] as string[]).includes(it))
}