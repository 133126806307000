import {PlanService} from '../PlanService'
import {ManagedLifeRepository} from '@peachy/flash-repo-peachy-client'
import {BankDetails, BenefitType, Enquiry, obfsucateBankAccountNumber, Question, QuestionIds} from '@peachy/repo-domain'
import {EnquiryReader} from './EnquiryReader'
import {currencyFromPence, Optional, possessiveNoun, readableDate} from '@peachy/utility-kit-pure'
import {subDays} from 'date-fns'
import {transform} from 'lodash-es'

export class EnquiryQuestionDataModelProviders {

    constructor(protected readonly planService: PlanService,
                protected readonly managedLifeRepository: ManagedLifeRepository) {}


    coverCheckCoverRemainingDataModelProvider = () => async (enquiry: Enquiry, question: Question) => {
        const enquiryReader = EnquiryReader.forClaimActivity(enquiry)
        const treatmentReceiverId = enquiryReader.extractTreatmentReceiverLifeIdFrom(enquiry)
        const currentPlanYear = await this.planService.getCurrentPlanYearFor(treatmentReceiverId)

        const data = {
            planRefreshDate: currentPlanYear ? readableDate(subDays(currentPlanYear.end, 1)) : undefined,
            excessOutstanding: currencyFromPence(currentPlanYear?.excess?.amountOutstandingInPence),
            ...transform(currentPlanYear.coverCheckableBenefits, (collector, benefit) => {
                collector[benefit.type] = {
                    excessIsApplicable: !!currentPlanYear.getExcessApplicableTo(benefit.type),
                    coverRemaining: currencyFromPence(benefit.limitRemaining)
                }
                return collector
            }, {} as Record<BenefitType, any>)
        }

        return data
    }

    whoIfNotPrimaryLifeDataModelProvider = () => async (enquiry: Enquiry, question: Question) => {
        const whoForId = await EnquiryReader.getWhoForIdLifeNameIfNotPrimaryLife(enquiry)
        const whoForLifeIfNotPrimary = await this.managedLifeRepository.find(it => it.id === whoForId && !it.isPrimary)
        const whoFor = whoForLifeIfNotPrimary?.firstName
        return {whoFor, possessiveWhoFor: possessiveNoun(whoFor)}
    }

    savedBankDetailsDataModelProvider() {
        return async (enquiry: Enquiry, question: Question) => {
            const model = {obfuscatedSavedBankAccount: undefined as string}
            const savedBankAccount  = enquiry.getFirstAnswerTo<Optional<BankDetails>>(QuestionIds['saved-bank-account-details'])
            model.obfuscatedSavedBankAccount = obfsucateBankAccountNumber(savedBankAccount?.accountNumber)
            return model
        }
    }
}
