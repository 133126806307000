import {Enquiry, EnquiryDefinitionId, QuestionAnswer, EnquiryDefinition, QuestionInitialisationData} from '@peachy/repo-domain'

import {Dictionary, groupDistinctBy} from '@peachy/utility-kit-pure'

export class EnquiryDefinitionService {

    readonly enquiryDefinitionById: Record <EnquiryDefinitionId, EnquiryDefinition>

    constructor(protected readonly enquiryDefinitions: EnquiryDefinition[]) {
        this.enquiryDefinitionById = groupDistinctBy(enquiryDefinitions, it => it.id)
    }

    public async initialiseQuestionsOn(enquiry: Enquiry, questionInitData: QuestionInitialisationData, initAnswers?: Dictionary<QuestionAnswer[]>) {
        const definition = this.enquiryDefinitionById[enquiry.definition]
        enquiry.questions = definition.questions.map(([initialiseQuestion]) => initialiseQuestion(questionInitData))
        this.initialiseAnswersOn(enquiry, initAnswers)
        await this.updateRequiredQuestionsOn(enquiry)
        await this.buildCurrentQuestionDataModel(enquiry)
    }

    initialiseAnswersOn(enquiry: Enquiry, initAnswers?: Dictionary<QuestionAnswer[]>) {
        Object.entries(initAnswers || {}).forEach(([questionId, initialAnswers]) => {
            enquiry.getQuestion(questionId)!.satisfyWith(initialAnswers)
        })
    }

    async updateRequiredQuestionsOn(enquiry: Enquiry) {
        const definition = this.enquiryDefinitionById[enquiry.definition]
        for (const [questionInitialiser, config] of definition.questions) {
            const requiredPredicate = config?.required
            if (requiredPredicate) {
                const question = enquiry.getQuestionEvenIfNotRequired(questionInitialiser.id)
                question?.setRequired(await requiredPredicate(enquiry, question))
            }
        }
    }

    async buildCurrentQuestionDataModel(enquiry: Enquiry) {
        const currentQuestion = enquiry.getFirstUnsatisfiedQuestion()
        if (currentQuestion) {
            const dataModelProviders = this.enquiryDefinitionById[enquiry.definition].questions.find(([initialiser]) => initialiser.id === currentQuestion.id)[0]?.dataModelProviders
            let dataModel = {}
            for (const dataModelProvider of (dataModelProviders ?? [])) {
                const model = await dataModelProvider(enquiry, currentQuestion)
                dataModel = {...dataModel, ...model}
            }
            currentQuestion.dataModel = dataModel
        }
    }

    getDefinition<T extends EnquiryDefinition>(definitionId: EnquiryDefinitionId) {
        return this.enquiryDefinitionById[definitionId] as T
    }
}
