import { Dictionary, enumerate } from '@peachy/utility-kit-pure'
import { Policy } from '../Policy'
import { Question } from './Question'
import { Enquiry } from './Enquiry'

export type ModalDefinition = {
    id: string
    header: string
    cannotDismiss?: boolean
} & (
    { body: string, Content?: never } |
    { body?: never, Content: () => React.ReactNode }
)

export type EnquiryDefinitionId = 'make-claim' | 'cover-check' | 'book-virtual-gp' | 'book-physio'

export const QuestionIds = enumerate([
    'who-are-you-checking-cover-for' ,
    'what-benefit-are-you-checking' ,
    'dental-cover-remaining-info' ,
    'dental-care-covered-list-info' ,
    'dental-care-excluded-list-info' ,
    'optical-cover-remaining-info' ,
    'optical-care-covered-list-info' ,
    'optical-care-excluded-list-info' ,
    'early-exit-dental' ,
    'early-exit-optical' ,
    'therapies-cover-remaining-info' ,
    'mental-health-cover-remaining-info' ,
    'consultations-cover-remaining-info' ,
    'hospital-care-cover-remaining-info' ,
    'what-type-of-therapist-do-you-need' ,
    'what-type-of-mental-health-care-do-you-need' ,
    'what-other-mental-health-care-do-you-need' ,
    'what-conditions-and-symptoms' ,
    'when-did-symptoms-start' ,
    'what-treatment-or-tests-do-you-need' ,
    'what-care-do-you-need' ,
    'have-you-been-referred' ,
    'have-you-got-referral-to-hand' ,
    'have-not-been-referred' ,
    'referral-not-to-hand-info' ,
    'you-can-self-refer' ,
    'will-you-see-ascenti' ,
    'yes-will-see-ascenti' ,
    'not-sure-if-will-see-ascenti' ,
    'prepare-to-capture-referral-docs' ,
    'capture-referral-docs' ,
    'do-you-know-the-care-cost' ,
    'do-you-know-the-treatment-cost' ,
    'what-is-the-total-cost' ,
    'existing-conditions-info' ,
    'excluded-conditions-info' ,
    'thanks-all-done' ,
    'who-are-you-claiming-for' ,
    'have-you-checked-your-cover' ,
    'remember-to-check-cover-next-time' ,
    'what-are-you-claiming-for' ,
    'what-type-of-therapist-did-you-see' ,
    'when-treated' ,
    'when-admitted' ,
    'when-discharged' ,
    'prepare-to-record-video' ,
    'record-what-was-wrong-video' ,
    'record-what-treatments-video' ,
    'record-anything-else-video' ,
    'prepare-to-capture-receipts' ,
    'capture-receipts' ,
    'collect-bank-account-details' ,
    'saved-bank-account-details' ,
    'should-save-bank-account-details' ,
    'claiming-on-another-policy' ,
    'other-policy-details' ,
    'sign-promise' ,
    'submit-claim' ,
    'out-of-hours-info' ,
    'who-are-you-booking-for' ,
    'which-phone-number' ,
    'new-phone-number' ,
    'appointment-reason' ,
    'any-related-medical-documents' ,
    'capture-related-medical-docs' ,
    'is-this-your-gp' ,
    'new-registered-gp' ,
    'what-gp-contact-method' ,
    'what-gp-gender-preference' ,
    'what-date-and-time' ,
    'will-you-be-at-home' ,
    'where-will-you-be' ,
    'submit-virtual-gp-booking' ,
    'what-physio-clinic-id' ,
    'what-physio-therapist_id' ,
    'who-are-you-booking-physio-for' ,
    'chaperone-physio-info' ,
    'what-physio-gender-preference' ,
    'what-date-and-time-physio' ,
    'new-or-existing-injury-physio' ,
    'already-had-initial-assessment-physio' ,
    'phone-the-provider-physio' ,
    'injury-location-physio' ,
    'anything-else-physio' ,
    'submit-physio-booking' ,
    'who-is-the-app-owner',
    'childs-dob',
    'adoption-date',
] as const)
export type QuestionId = keyof typeof QuestionIds

type ThirdPartyConfig = { healthHero: { bookingPhoneNumber: string }, ascenti: { bookingPhoneNumber: string } }
export type QuestionInitialisationData = { policy: Policy, config: ThirdPartyConfig }

export interface QuestionInitialiser {
    id: QuestionId,

    // should maybe collapse initialiser and dataModelProviders?
    (initData?: QuestionInitialisationData): Question,

    dataModelProviders?: ((enquiry: Enquiry, question: Question) => Promise<Dictionary<string>>)[]
}

export type QuestionRequiredPredicate = (enquiry: Enquiry, question: Question) => Promise<boolean>

export type QuestionInitialiserAndConfigTuple = [QuestionInitialiser, {
    required?: QuestionRequiredPredicate
}?]

export type EnquiryDefinition = {
    id: EnquiryDefinitionId
    questions: QuestionInitialiserAndConfigTuple[]
}