import {Obligations, RepoBenefit, RepoPolicy} from '@peachy/repo-domain'
import {changeSet} from './peachyChangeSet'
import {Dictionary, newUUID} from '@peachy/utility-kit-pure'
import {PNode} from '@peachy/flash-repo-pure'

type v106_ota_2_aware_RepoBenefit = {
    id: string
    type: string
    startDate: string
    coverAmountInPence: number
    endDate?: string
}

type v106_ota_2_aware_RepoClaim = {
    benefitId: string
    userChosenBenefitName: string
}

export const changeSets = [
    changeSet({id: '001-migrate-benefits-and-claims', author: 'dan.parker'}, async tx => {

        const policy = await tx.policy()

        const benefitsTypesById: Dictionary<string> = {}
        for (const [index, plan] of (await tx.plans()).entries()) {
            const newBenefits = mapOldToNewBenefits(plan.benefits as v106_ota_2_aware_RepoBenefit[], policy, plan.startDate)
            await tx.plans[index].benefits(newBenefits)
            // create a map of benefit types by id to use later when updating claim activities
            newBenefits.forEach(it => benefitsTypesById[it.id] = it.type)
        }

        for (const [index, _claim] of (await tx.claimActivities()).entries()) {
            const claim = _claim as unknown as v106_ota_2_aware_RepoClaim
            let benefitType = undefined
            if (claim.benefitId) {
                benefitType = benefitsTypesById[(claim as unknown as v106_ota_2_aware_RepoClaim).benefitId]
            } else if (claim.userChosenBenefitName === 'Mental health') { // an undefined benefitId on a claim would be mental health coz that's how it used to work.  we're making sure here before migrating though
                benefitType = 'MENTAL_HEALTH'
            }
            //this should never happen, but...
            if (!benefitType) {
                throw `claim activity with unexpectedly unmappable benefit type.  benefitId: ${claim.benefitId}, userChosenBenefitName: ${claim.userChosenBenefitName}`
            }

            await tx.claimActivities[index].customerDeclaredBenefitType(benefitType)

            const claimNode = tx.claimActivities[index] as unknown as PNode<v106_ota_2_aware_RepoClaim, 'MUTABLE'>
            await claimNode.userChosenBenefitName(undefined)
            await claimNode.benefitId(undefined)

            // clear any inprogress stuff coz they will have the incorrect metadata in the enquiry:
            await tx.inProgress({})
        }

    })
]

function mapOldToNewBenefits(oldBenefits: v106_ota_2_aware_RepoBenefit[], policy: RepoPolicy, planStartDate: string): RepoBenefit[] {
    const newBenefits = oldBenefits.map(oldBenefit => {
        const newBenefit: RepoBenefit = {
            id: oldBenefit.id,
            type: oldBenefit.type,
            // @ts-ignore parentType has been superseeded with parent/child relationships defined in ProductConfigService but it still needs to be set here to support the migration (created before it was removed)
            parentType: ['MENTAL_HEALTH_IN_PATIENT', 'MENTAL_HEALTH_OUT_PATIENT'].includes(oldBenefit.type) ? 'MENTAL_HEALTH' : undefined,
            startDate: oldBenefit.startDate,
            endDate: oldBenefit.endDate,
            utilisationPolicy: {
                limit: {
                    unit: 'PENCE',
                    value: oldBenefit.coverAmountInPence
                },
                CLAIM: {
                    obligation: Obligations.MANDATORY,
                    lodgementWindowInWeeks: 16
                },
                COVER_CHECK: {
                    obligation: ['DENTAL', 'OPTICAL'].includes(oldBenefit.type) ? Obligations.OPTIONAL : Obligations.MANDATORY
                }
            }
        }
        return newBenefit
    })

    newBenefits.push({
        id: newUUID(),
        type: 'VIRTUAL_GP',
        startDate: planStartDate
    })

    if (policy.accountType === 'COMPANY') {
        newBenefits.push({
            id: newUUID(),
            type: 'ADVICE_AND_COUNSELLING',
            startDate: planStartDate
        })
    }

    return newBenefits
}
