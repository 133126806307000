import {
    imReadyOptions,
    ProductConfigService,
    QuestionOption,
    reviewSubmitOptions,
    useSavedAccountOption,
    whoOptions,
    yesNoOptions,
    yesOption,
    QuestionIds,
    QuestionInitialisationData,
    QuestionInitialiser,
    QuestionInitialiserAndConfigTuple,
    Enquiry
} from '@peachy/repo-domain'
import {EnquiryQuestionDataModelProviders} from '../../EnquiryQuestionDataModelProviders'
import {max, subYears} from 'date-fns'
import {EnquiryDefinitionCommon} from '../EnquiryDefinitionCommon'
import {
    buttonQuestion,
    captureImagesAndFilePickerQuestion,
    fullDatePickerQuestion,
    requiredWhenMoreThanOneOption,
    signatureQuestion,
    singleAnswerBankDetailsQuestion,
    singleAnswerCheckboxQuestion,
    singleAnswerFreetextQuestion,
    singleAnswerNumberQuestion,
    singleAnswerPickerQuestion,
    when
} from '../EnquiryDefinitionUtil'
import { Dictionary } from '@peachy/utility-kit-pure'

export class SovereignMakeClaimEnquiryDefinition extends EnquiryDefinitionCommon {

    readonly id = 'make-claim'
    readonly questions: QuestionInitialiserAndConfigTuple[]

    constructor(protected readonly dataModelProviders: EnquiryQuestionDataModelProviders, protected readonly productService: ProductConfigService) {
        super()
        this.questions = this.buildQuestions()
    }

    buildQuestions(): QuestionInitialiserAndConfigTuple[] {

        return [
            // unasked.. i.e expected to be initiated before chat
            [this.whoIsTheAppOwner],
            [this.whatAreTheSavedBankDetails],

            [this.whoAreYouClaimingFor, requiredWhenMoreThanOneOption],
            [this.whatAreYouClaimingFor],
            
            [this.whenTreated, this.requiredWhenTheChosenBenefitMandatesIt],
            [this.whenAdmitted, this.requiredWhenTheChosenBenefitMandatesIt],
            [this.whenDischarged, this.requiredWhenTheChosenBenefitMandatesIt],
            [this.whatIsTheTotalCost, this.requiredWhenTheChosenBenefitMandatesIt],
            [this.whatIsTheChildsDob, this.requiredWhenTheChosenBenefitMandatesIt],
            [this.whatIsTheAdoptionDate, this.requiredWhenTheChosenBenefitMandatesIt],

            [this.prepareToCaptureReceipts],
            [this.captureReceipts],
            [this.collectBankAccountDetails],
            [this.saveOrReplaceBankAccountDetails, {
                required: when({
                    noAnswersOf: [[this.collectBankAccountDetails, useSavedAccountOption]]
                })
            }],
            [this.claimingOnAnotherPolicy],
            [this.otherPolicyDetails, {
                required: when({
                    answersOf: [[this.claimingOnAnotherPolicy, yesOption]]
                })
            }],
            [this.signPromise],
            [this.reviewAndSubmitClaim],
        ]
    }

    get whatAreTheSavedBankDetails (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerBankDetailsQuestion({
            id: question.id,
            text: 'What are the saved bank details?',
            tags: [
                // should capture name but just make it non-editable in the view and initialise it when the enquiry starts
                `${EnquiryDefinitionCommon.accHolderNameTag}${policy.primaryLife.fullName}`,
                EnquiryDefinitionCommon.hideQuestionInChatTag
            ]
        })
        question.id = QuestionIds['saved-bank-account-details']
        return question
    }

    //todo-dp another child not on the policy flow
    get whoAreYouClaimingFor (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Hi ${policy.primaryLife.firstName}, who are you claiming for today?`,
            embeddedOptions: whoOptions(policy.managedLivesThatAreAllowedToMakeClaims)
        })
        question.id = QuestionIds['who-are-you-claiming-for']
        return question
    }

    get whatAreYouClaimingFor (): QuestionInitialiser {
        const question = () => singleAnswerPickerQuestion({
            id: question.id,
            text: 'Ok, for which benefit?',
            optionsId: 'claimable-benefits'
        })
        question.id = QuestionIds['what-are-you-claiming-for']
        return question
    }

    get whenTreated (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const oneYearAgo = subYears(new Date(), 1)
            const minAccepted = max([policy.startDate, oneYearAgo])

            return fullDatePickerQuestion({
                id: question.id,
                text: 'When did ${whoFor ? whoFor : "you"} get treatment?',
                minAccepted,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['when-treated']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }
    
    get whenAdmitted (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const oneYearAgo = subYears(new Date(), 1)
            const minAccepted = max([policy.startDate, oneYearAgo])

            return fullDatePickerQuestion({
                id: question.id,
                text: 'When did ${whoFor ? whoFor : "you"} get admitted for treatment?',
                minAccepted,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['when-admitted']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }
    
    get whenDischarged (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const oneYearAgo = subYears(new Date(), 1)
            const minAccepted = max([policy.startDate, oneYearAgo])

            return fullDatePickerQuestion({
                id: question.id,
                text: 'When did ${whoFor ? whoFor : "you"} get discharged?',
                minAccepted,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['when-discharged']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }

    get whatIsTheTotalCost (): QuestionInitialiser {
        const question = () => singleAnswerNumberQuestion({
            id: question.id,
            text: 'And how much did it cost?',
            tags: ['icon:Currency', 'iconSize:15', 'iconColor:black']
        })
        question.id = QuestionIds['what-is-the-total-cost']
        return question
    }

    get whatIsTheChildsDob (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const eighteenYearsAgo = subYears(new Date(), 1)
            const minAccepted = eighteenYearsAgo //todo-dppq what should this be?
            return fullDatePickerQuestion({
                id: question.id,
                text: "What is your child's date of birth",
                minAccepted,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['childs-dob']
        return question
    }
    
    get whatIsTheAdoptionDate (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const oneYearAgo = subYears(new Date(), 1)
            const minAccepted = max([policy.startDate, oneYearAgo]) //todo-dppq what should this be?

            return fullDatePickerQuestion({
                id: question.id,
                text: 'And what is the adoption date?',
                minAccepted,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['adoption-date']
        return question
    }

    get prepareToCaptureReceipts (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "${blurb}",
            embeddedOptions: imReadyOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['prepare-to-capture-receipts']
        question.dataModelProviders = [this.captureReceiptsBlurbProvider]
        return question
    }

    get captureReceipts (): QuestionInitialiser {
        const question = () => captureImagesAndFilePickerQuestion({
            id: question.id,
            text: '',
            tags: [EnquiryDefinitionCommon.textractInvoiceTag]
        })
        question.id = QuestionIds['capture-receipts']
        return question
    }


    get collectBankAccountDetails (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerBankDetailsQuestion({
            id: question.id,
            text: 'Please add your bank details so we can process your payment as fast as possible!',
            // should capture name but just make it non-editable in the view and initialise it when the enquiry starts rather than using tags
            tags: [`${EnquiryDefinitionCommon.accHolderNameTag}${policy.primaryLife.fullName}`]
        })
        question.id = QuestionIds['collect-bank-account-details']
        question.dataModelProviders = [this.dataModelProviders.savedBankDetailsDataModelProvider()]
        return question
    }

    get saveOrReplaceBankAccountDetails (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: "Do you want us to ${obfuscatedSavedBankAccount ? 'replace your existing' : 'save these'} bank details for next time?",
            embeddedOptions: yesNoOptions
        })
        question.dataModelProviders = [this.dataModelProviders.savedBankDetailsDataModelProvider()]
        question.id = QuestionIds['should-save-bank-account-details']
        return question
    }


    get claimingOnAnotherPolicy (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Are you making a claim for this treatment under another policy? Either Sovereign or another provider',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['claiming-on-another-policy']
        return question
    }


    get otherPolicyDetails (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'Please provide the details of the other policy, including the name of the company and policy number'
        })
        question.id = QuestionIds['other-policy-details']
        return question
    }


    get signPromise (): QuestionInitialiser {
        const question = () => signatureQuestion({
            id: question.id,
            text: 'Here at Sovereign we believe in trust!',
            helpHeading: 'Your promise to us',
            helpText: 'By signing this statement, I confirm that the information provided for this claim is true and accurate'
        })
        question.id = QuestionIds['sign-promise']
        return question
    }


    get reviewAndSubmitClaim (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'Great! Please take a moment to review this claim before you submit',
            embeddedOptions: reviewSubmitOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['submit-claim']
        return question
    }

    private get requiredWhenTheChosenBenefitMandatesIt() {
        return {
            required: when({
                rawPredicate: async (enquiry, question) => {
                    const chosenBenefitType = enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-are-you-claiming-for'])?.id
                    const benefitDependentQuestionIds = this.productService.getClaimLodgementQuestionIdsFor(chosenBenefitType)
                    return benefitDependentQuestionIds.includes(question.id)
                }
            })
        }
    }

    private get captureReceiptsBlurbProvider() { 
        return async (enquiry: Enquiry) => {
            const chosenBenefitType = enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-are-you-claiming-for'])?.id
            const model = { blurb: benefitSpecificCaptureReceiptBlurb[chosenBenefitType] ?? standardCaptureReceiptsBlurb()}
            return model
        }
    }
}

const defaultCaptureReceiptsChecklist = `
• patient full name
• practice contact details
• details and cost of treatment
• date of treatment
• date of payment`

function standardCaptureReceiptsBlurb(checklist?: string) {
    return `Please upload or take a picture of your documents(s), please make sure they contain: \n${checklist ?? defaultCaptureReceiptsChecklist}`
}

const benefitSpecificCaptureReceiptBlurb: Dictionary<string> = {
    'BIRTH_ADOPTION.ADOPTION': `Please upload or take a picture of your child's adoption certificate, please make sure it contains:

• parent(s) full name
• date of birth`,

    'BIRTH_ADOPTION.BIRTH': `Please upload or take a picture of your child's birth certificate, please make sure it contains:

• parent(s) full name
• date of birth`,
    
    'PRESCRIPTION.PRESCRIPTION': standardCaptureReceiptsBlurb(`
• patient full name
• pharmacy contact details
• name and cost of prescription
• date of payment`),
    
    'PRESCRIPTION.VACCINATION': standardCaptureReceiptsBlurb(`
• patient full name
• pharmacy contact details
• name and cost of vaccination
• date of payment`),

    HOSPITAL_IN_PATIENT: standardCaptureReceiptsBlurb(`
• patient full name
• hospital contact details
• details and cost of treatment
• date of hospital admission and discharge
• hospital claim form or discharge papers`)
}