import {Logger} from '@peachy/utility-kit-pure'
import {
    AscentiAppointmentBookOut,
    AscentiAppointmentCancelOut,
    AscentiAppointmentHoldOut,
    AscentiInjury,
    SerialisedAscentiAppointment
} from '@peachy/ascenti-client'
import {formatISO} from 'date-fns'
import {SyncRequest, SyncResponse} from '@peachy/flash-repo-pure'
import {PeachyPaymentCard} from '@peachy/payments-pure'
import {IdCheckRequest, IPeachyClient, SubmitClaimsActivity, SubmitClaimsActivityResponse} from './IPeachyClient'
import {Storage, StoragePutConfig} from '@aws-amplify/storage'
import {API} from '@aws-amplify/api'
import {ILambdaApiProvider} from './lambda/ILambdaApiProvider'
import { Auth } from '@aws-amplify/auth'

export type ApiResponse = {data: any, headers: Record<string, string>}
export type ApiResponseWrapper = (response: ApiResponse) => Promise<void>

export class PeachyClient implements IPeachyClient {

    protected readonly claimsApi
    protected readonly intercomApi
    protected readonly nhsApi
    protected readonly ascentiApi
    protected readonly marketplaceApi
    protected readonly flashRepoApi
    protected readonly paymentsApi
    protected readonly idCheckApi

    protected readonly policyApi
    protected readonly subscriptionApi
    protected readonly nhsLoginApi

    constructor(public readonly api: typeof API,
                public readonly auth: typeof Auth,
                protected readonly storage: typeof Storage,
                protected readonly lambdaApiProvider: ILambdaApiProvider,
                protected readonly logger: Logger,
                protected readonly responseWrapper?: ApiResponseWrapper) {

        this.claimsApi = this.buildAwsClientFor('ClaimsActivity')
        this.intercomApi = this.buildAwsClientFor('Intercom')
        this.nhsApi = this.buildAwsClientFor('NhsApi')
        this.ascentiApi = this.buildAwsClientFor('Ascenti')
        this.marketplaceApi = this.buildAwsClientFor('MarketplaceApi')
        this.flashRepoApi = this.buildAwsClientFor('FlashRepoApi')
        this.policyApi = this.buildAwsClientFor('PolicyApi')
        this.paymentsApi = this.buildAwsClientFor('PaymentsApi')
        this.idCheckApi = this.buildAwsClientFor('IdCheckApi')
        this.subscriptionApi = this.buildAwsClientFor('SubscriptionStackApi')
        this.nhsLoginApi = undefined as any //todo reinstate this once nhs login stack is deployed in the back end: this.buildAwsClientFor('NhsLoginApi')
    }

    async submitClaimsActivity(details: SubmitClaimsActivity) {
        return this.claimsApi.POST<SubmitClaimsActivityResponse>('/claim/submit', {body: details})
    }

    async commentOnClaimsActivity(claimId: string, message: string) {
        return this.claimsApi.POST('/claim/' + claimId + '/comment', {body: {message}})
    }

    async getIntercomUser(platformOs: string) {
        return this.intercomApi.GET<{hash: string}>('/user/self?source=' + platformOs)
    }

    async searchGps(searchTerm: string, postcode: string, limit = 5) {
        return this.nhsApi.POST<any>('/find-gp', {
            body: {
                searchTerm,
                postcode,
                count: limit
            }
        })
    }

    async getAscentiAppointmentAvailability(clinicId: number, therapistId?: number) {
        const baseUrl = `/clinic/${clinicId}` + (therapistId ? `/therapist/${therapistId}` : '')
        return this.ascentiApi.GET<SerialisedAscentiAppointment[]>(baseUrl + '/appointmentAvailability')
    }

    async holdAscentiAppointment(clinicId: number, therapistId: number, dateTime: Date, length: number) {
        return this.ascentiApi.POST<AscentiAppointmentHoldOut>('/appointment/hold', {
            body: {
                clinicId,
                therapistId,
                dateTime: formatISO(dateTime),
                length
            }
        })
    }

    async bookHeldAscentiAppointment(ascentiAppointmentId: number, whoForId: string, acceptTerms: boolean, injury: AscentiInjury) {
        return this.ascentiApi.POST<AscentiAppointmentBookOut>('/appointment/book', {
            body: {
                ascentiAppointmentId,
                whoForId,
                acceptTerms,
                injury
            }
        })
    }

    async cancelAscentiAppointment(ascentiAppointmentId: number, cancellationToken: string, caseReference: string, caseId: number) {
        return this.ascentiApi.POST<AscentiAppointmentCancelOut>('/appointment/cancel', {
            body: {
                ascentiAppointmentId,
                cancellationToken,
                caseReference,
                caseId
            }
        })
    }

    async searchMarketplace(searchQuery: any) {
        return this.marketplaceApi.POST('lookup', {
            body: searchQuery
        })
    }

    async syncRemoteRepo(syncRequest: SyncRequest) {
        return this.flashRepoApi.POST<SyncResponse>('sync', {
            body: {
                syncRequest
            }
        })
    }

    async stagePolicyDocGen() {
        return this.policyApi.GET<{ticketId: string}>('user/self/policy-doc/stage')
    }

    async buildFetchStagedDocUrl(stagedTicketId: string) {
        const api = await this.lambdaApiProvider.docApi()
        return api.getPdfLink(stagedTicketId, `peachy-policy-${stagedTicketId}.pdf`)
    }

    async getCustomerPaymentCards() {
        return this.paymentsApi.GET<PeachyPaymentCard[]>('customer/cards')
    }

    async submitIdCheck(request: IdCheckRequest) {
        return this.idCheckApi.POST<any>('check', {
            body: request
        })
    }

    async exchangeNhsLoginCodeForJwtToken(code: string, codeVerifier: string, redirectUri: string) {
        return 'feature not implemented yet'
        // todo reinstate the below once nhs login stack deployed
        // return await this.nhsLoginApi.POST<string>(`token/exchange`, {
        //     body: {
        //         code,
        //         codeVerifier,
        //         redirectUri
        //     }
        // })
    }

    async resolveAwsUserContentBucket() {
        return this.subscriptionApi.POST('resolve-content-bucket')
    }

    uploadCustomerContent(bucketKey: string, content: Blob, contentType: string, tagging?: string) {

        const config: StoragePutConfig<Record<string, any>> = {
            level: 'private',
            contentType: contentType,
            tagging,
            progressCallback: (progress) => {
                this.logger.debug(`Uploaded part: ${progress.loaded}/${progress.total} for ${bucketKey}`)
            },
            resumable: false
            // Resumable is broken
            // https://github.com/aws-amplify/amplify-js/issues/11308#issuecomment-1577294520
            // completeCallback: (event) => {
            //     resolve(`Upload complete: ${event.key}`)
            // },
            // errorCallback: (err) => {
            //     this.logger.error('Failure during upload', err)
            //     reject(err)
            // }
        }
        // returns a UploadTask object if resumable flag is set to true
        const request = this.storage.put(bucketKey, content, config)
        return {result: request, cancel: () => this.storage.cancel(request)}
    }

    //todo-should move out of here any only make available to admin clients that need it
    async getMediaLinksForClaimsActivity(customerAwsSub: string, claimId: string): Promise<string[]> {
        const api = await this.lambdaApiProvider.claimsActivityApi()
        const response = await api.adminGetMediaUris({claimId, sub: customerAwsSub})
        return response
    }

    //todo-should move out of here any only make available to admin clients that need it
    async getThirdPartyUriFor(sub: string, endpoint: 'stripeCustomer' | 'intercomContact') {
        const api = await this.lambdaApiProvider.userAdminApi()
        return api.getThirdPartyUri({sub, endpoint})
    }

    //todo-should move out of here any only make available to admin clients that need it
    async adminSyncRemoteRepo(sub: string, syncRequest: SyncRequest) {
        const api = await this.lambdaApiProvider.adminRepoApi()
        return api.adminSync({sub, syncRequest})
    }

    private buildAwsClientFor(endpointName: string) {
        const {logger, responseWrapper, api} = this
        return {
            POST<T>(path: string, init?: Record<string, any>) { return requestHandler<T>(endpointName, 'post', path, logger, api, {init, responseWrapper}) },
            GET<T>(path: string, init?: Record<string, any>) { return requestHandler<T>(endpointName, 'get', path, logger, api, {init, responseWrapper}) },
            PUT<T>(path: string, init?: Record<string, any>) { return requestHandler<T>(endpointName, 'put', path, logger, api, {init, responseWrapper}) },
            DELETE<T>(path: string, init?: Record<string, any>) { return requestHandler<T>(endpointName, 'del', path, logger, api, {init, responseWrapper}) },
            HEAD<T>(path: string, init?: Record<string, any>) { return requestHandler<T>(endpointName, 'head', path, logger, api, {init, responseWrapper}) },
            PATCH<T>(path: string, init?: Record<string, any>) { return requestHandler<T>(endpointName, 'patch', path, logger, api, {init, responseWrapper}) },
            endpoint: async () => api.endpoint(endpointName)
        }
    }
}

async function requestHandler<T>(endpointName: string, method: 'post' | 'get' | 'put' | 'del' | 'head' | 'patch', path: string, logger: Logger, api: typeof API, {init, responseWrapper}: {init?: Record<string, any>, responseWrapper?: ApiResponseWrapper}): Promise<T> {
    const options = init ?? {}
    options.response = true
    try {
        const response = await api[method](endpointName, path, options)
        await responseWrapper?.(response)
        return response.data as T
    } catch (e) {
        logger.error(e, {name: `peachy-client-${endpointName}`, message: `path: ${path}`})
        throw e
    }
}
