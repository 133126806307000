import {BenefitLimit, Obligation, RepoBenefit} from '@peachy/repo-domain'
import {changeSet} from './peachyChangeSet'

type v107_ota_1_aware_RepoBenefit = {
    id: string
    type: string
    startDate: string
    endDate?: string
    parentType?: string
    utilisationPolicy?: {
        COVER_CHECK?: { 
            obligation?: Obligation
        }
        CLAIM?: {
            obligation?: Obligation 
            lodgementWindowInWeeks: number
        }
        limit?: BenefitLimit
    }
}

export const changeSets = [
    changeSet({id: '001-migrate-benefits-utilisation-policy', author: 'dan.parker'}, async tx => {
        for (const [index, plan] of (await tx.plans()).entries()) {
            const newBenefits = mapOldToNewBenefits(plan.benefits)
            await tx.plans[index].benefits(newBenefits)            
        }
    })
]

function mapOldToNewBenefits(oldBenefits: v107_ota_1_aware_RepoBenefit[]): RepoBenefit[] {
    const newBenefits = oldBenefits.map(oldBenefit => {
        // remove parentType and utilisationPolicy from new benefit base
        const {parentType: _, utilisationPolicy: oldBenefitUtilisationPolicy, ...newBenefitBase} = oldBenefit
        
        // copy limit to root of the new benefit.
        let newBenefit: RepoBenefit = {
            ...newBenefitBase,
            limit: oldBenefitUtilisationPolicy?.limit
        }

        // leave utilisationPolicy.limit in place on new benefit for temp backwards compatibility (will be removed in subsequent releases)
        if (oldBenefitUtilisationPolicy) {
            newBenefit = {
                ...newBenefit,
                utilisationPolicy: {
                    limit: oldBenefitUtilisationPolicy.limit
                }
            }
        }

        return newBenefit
    })

    return newBenefits
}
