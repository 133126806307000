import 'reflect-metadata'
import {DomainMappings} from '../mappings/DomainMappings'
import {Life} from './Life'
import {Decision} from './Decision'
import {BenefitType, ClaimStage, ClaimStages, DecisionTypes} from './types'
import {Enquiry} from './enquiry/Enquiry'
import {Type} from 'class-transformer'
import {ClaimAssessment} from './assessment/ClaimAssessment'
import {sortBy} from 'lodash-es'

class CommonClaimActivity {

    @Type(() => Date)
    readonly dateCreated: Date

    constructor(readonly id: string,
                readonly stage: ClaimStage,
                dateCreated: Date) {
        this.dateCreated = dateCreated
    }

    public isClaim() {
        return this.stage === ClaimStages.CLAIM
    }

    public isCoverCheck() {
        return this.stage === ClaimStages.COVER_CHECK
    }

    get referenceNumber() {
        return DomainMappings.peachifyUuid(this.id)
    }
}

export type ClaimSubmissionIdentifier = {
    message: {id: string}
    conversation: {id?: string},
}

export class ClaimActivity extends CommonClaimActivity {

    @Type(() => Life)
    readonly treatmentReceiver: Life
    @Type(() => Date)
    readonly treatmentDateFrom: Date
    @Type(() => Date)
    readonly treatmentDateTo: Date
    @Type(() => Date)
    readonly dateSubmitted: Date

    constructor(id: string,
                stage: ClaimStage,
                dateCreated: Date,

                readonly enquiryId: string,
                readonly submissionId: ClaimSubmissionIdentifier | undefined,
                dateSubmitted: Date,
                readonly customerDeclaredBenefitType: BenefitType,
                readonly treatment: string,
                treatmentReceiver: Life,
                readonly costInPence: number | undefined,
                treatmentDateFrom: Date | undefined,
                treatmentDateTo: Date | undefined,
                readonly decision: Decision | undefined,
                readonly amountReimbursedInPence: number | undefined,
                readonly assessment: ClaimAssessment | undefined

    ) {
        super(id, stage, dateCreated)
        this.dateSubmitted = dateSubmitted
        this.treatmentReceiver = treatmentReceiver
        this.treatmentDateFrom = treatmentDateFrom
        this.treatmentDateTo = treatmentDateTo
    }

    public isDeclined() {
        return this.decision?.type === DecisionTypes.DECLINE
    }

    public isApproved() {
        return this.decision?.type === DecisionTypes.APPROVE
    }

    public isPendingDecision() {
        return !this.decision
    }

    public get assessedBenefits() {
        return [...new Set([...(this.assessment?.invoiceLineItems ?? []), ...(this.assessment?.requestedTreatments ?? [])].map(it => it.benefitType))]
    }

    public get assessedPlanYearIds() {
        const lineItems = this.assessment?.invoiceLineItems ?? []
        return sortBy([...new Set(lineItems.map(it => it.planYearId))], it => it)
    }

}

export class InProgressClaimActivity extends CommonClaimActivity {

    @Type(() => Enquiry)
    public enquiry: Enquiry

    constructor(id: string,
                stage: ClaimStage,
                dateCreated: Date,

                enquiry: Enquiry) {
        super(id, stage, dateCreated)
        this.enquiry = enquiry
    }
}
