import {
    bookNowOptions,
    genderPreferenceOptions,
    gotItOptions,
    newPhoneNumberOption,
    noOption,
    useExistingGpOption,
    videoOrPhoneOptions,
    whichPhoneNumberOptions,
    whichRegisteredGpOptions,
    whoOptions,
    yesNoOptions,
    yesOption,
    QuestionIds,
    QuestionInitialisationData,
    QuestionInitialiser,
    QuestionInitialiserAndConfigTuple
} from '@peachy/repo-domain'
import {phoneLinkMarkdown} from '@peachy/utility-kit-pure'
import {EnquiryQuestionDataModelProviders} from '../../EnquiryQuestionDataModelProviders'
import {EnquiryDefinitionCommon} from '../EnquiryDefinitionCommon'
import {
    buttonQuestion,
    captureImagesQuestion,
    requiredWhenMoreThanOneOption,
    singleAnswerAppointmentPickerQuestion,
    singleAnswerCheckboxQuestion,
    singleAnswerFreetextQuestion,
    singleAnswerOptionLookupQuestion,
    when
} from '../EnquiryDefinitionUtil'

export class BookVirtualGpEnquiryDefinition extends EnquiryDefinitionCommon {

    readonly id = 'book-virtual-gp'
    readonly questions: QuestionInitialiserAndConfigTuple[]

    constructor(protected readonly dataModelProviders: EnquiryQuestionDataModelProviders) {
        super()
        this.questions = this.buildQuestions()
    }

    buildQuestions(): QuestionInitialiserAndConfigTuple[] {

        const {
            whoIsTheAppOwner,
            outOfHoursInfo,
            whoAreYouBookingFor,
            whichPhoneNumber,
            newPhoneNumber,
            appointmentReason,
            anyRelatedMedicalDocuments,
            captureRelatedMedicalDocuments,
            isThisYourGp,
            newRegisteredGp,
            whatGpContactMethod,
            whatGpGenderPreference,
            whatDateAndTime,
            willYouBeAtHome,
            whereWillYouBe,
            reviewAndSubmitVirtualGpBooking
        } = this
        return [
            // unasked.. i.e initiated before chat
            [whoIsTheAppOwner],

            [outOfHoursInfo],
            [whoAreYouBookingFor, requiredWhenMoreThanOneOption],
            [whichPhoneNumber],
            [newPhoneNumber, {
                required: when({
                    answersOf: [[whichPhoneNumber, newPhoneNumberOption]]
                })
            }],
            [appointmentReason],
            [anyRelatedMedicalDocuments],
            [captureRelatedMedicalDocuments, {
                required: when({
                    answersOf: [[anyRelatedMedicalDocuments, yesOption]]
                })
            }],
            [isThisYourGp, requiredWhenMoreThanOneOption],
            [newRegisteredGp, {
                required: when({
                    noAnswersOf: [[isThisYourGp, useExistingGpOption]]
                })
            }],
            [whatGpContactMethod],
            [whatGpGenderPreference],
            [whatDateAndTime],
            [willYouBeAtHome],
            [whereWillYouBe, {
                required: when({
                    answersOf: [[willYouBeAtHome, noOption]]
                })
            }],
            [reviewAndSubmitVirtualGpBooking],
        ]
    }

    get outOfHoursInfo (): QuestionInitialiser {
        const question = ({policy, config}: QuestionInitialisationData) => {
            return buttonQuestion({
                id: question.id,
                text: `Hi ${policy.primaryLife.firstName}, before we start booking an appointment please note that if you need to *book and talk* to a Virtual GP between 22:00 and 08:00, please call ${phoneLinkMarkdown(config.healthHero.bookingPhoneNumber)} as this is our out of hours number.  Otherwise click below to carry on!`,
                embeddedOptions: gotItOptions,
                tags: EnquiryDefinitionCommon.hideAnswersInChat
            })
        }
        question.id = QuestionIds['out-of-hours-info']
        return question
    }

    get whoAreYouBookingFor (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Who are you booking for today?`,
            embeddedOptions: whoOptions(policy.activeManagedLives)
        })
        question.id = QuestionIds['who-are-you-booking-for']
        return question
    }

    get whichPhoneNumber (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Can you confirm the best contact number?',
            embeddedOptions: whichPhoneNumberOptions(policy.primaryLife.phoneNumber!)
        })
        question.id = QuestionIds['which-phone-number']
        return question
    }

    get newPhoneNumber (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: "No problem, what's a better contact number to use?",
            tags: EnquiryDefinitionCommon.forceNumericKeyboard
        })
        question.id = QuestionIds['new-phone-number']
        return question
    }

    get appointmentReason (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'Great!  So we can provide the best care, please give us some information on why you are booking an appointment today',
        })
        question.id = QuestionIds['appointment-reason']
        return question
    }

    get anyRelatedMedicalDocuments (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Do you have any relevant documents you would like to take a picture of? This can include medical history',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['any-related-medical-documents']
        return question
    }

    get captureRelatedMedicalDocuments (): QuestionInitialiser {
        const question = () => captureImagesQuestion({
            id: question.id,
            text: ''
        })
        question.id = QuestionIds['capture-related-medical-docs']
        return question
    }


    get isThisYourGp (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Is this still \${possessiveWhoFor ? possessiveWhoFor : 'your'} registered NHS GP?\n\n${policy.primaryLife.registeredGp?.toFormattedString()}`,
            embeddedOptions: whichRegisteredGpOptions(policy.primaryLife)
        })
        question.id = QuestionIds['is-this-your-gp']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get newRegisteredGp (): QuestionInitialiser {
        const question = () => singleAnswerOptionLookupQuestion({
            id: question.id,
            text: 'Please enter the name or postcode of ${whoFor ? "their" : "your"} NHS GP below',
            optionsId: 'find-gp'
        })
        question.id = QuestionIds['new-registered-gp']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }

    get whatGpContactMethod (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Great! Would you like to book a video call or a phone call with a Virtual GP?',
            embeddedOptions: videoOrPhoneOptions
        })
        question.id = QuestionIds['what-gp-contact-method']
        return question
    }

    get whatGpGenderPreference (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Would ${whoFor ? whoFor : "you"} like to speak to a male or female Virtual GP?',
            embeddedOptions: genderPreferenceOptions
        })
        question.id = QuestionIds['what-gp-gender-preference']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }

    get whatDateAndTime (): QuestionInitialiser {
        const question = () => singleAnswerAppointmentPickerQuestion({
            id: question.id,
            text: 'Please pick a date and time that suits ${whoFor ? "them" : "you"}',
            optionsId: 'vgp-appointment-times'
        })
        question.id = QuestionIds['what-date-and-time']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }

    //should fix - we're assuming main account holder address here. we should probably show the address we're assuming in the question?
    get willYouBeAtHome (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Will ${whoFor ? whoFor : "you"} be at home during this appointment? We need to know in case of emergency during the appointment',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['will-you-be-at-home']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }

    get whereWillYouBe (): QuestionInitialiser {
        const question = () => singleAnswerOptionLookupQuestion({
            id: question.id,
            text: 'Please let us know where ${whoFor ? "they" : "you"} will be instead',
            optionsId: 'address-lookup'
        })
        question.id = QuestionIds['where-will-you-be']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }

    get reviewAndSubmitVirtualGpBooking (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'All done! Please check the information that you have provided to make sure you are happy and then click Book now',
            embeddedOptions: bookNowOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['submit-virtual-gp-booking']
        return question
    }

}
