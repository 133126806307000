import {PeachyFlashRepo} from '../types'
import {RepoClaimActivity, RepoClaimAssessment} from '@peachy/repo-domain'
import {PeachyArrayRepository} from '@peachy/flash-repo-peachy-client'

export class ClaimActivityRepository extends PeachyArrayRepository<RepoClaimActivity> {

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.claimActivities)
    }

    async listSubmitted() {
        return this.filter(it => !!it.dateSubmitted)
    }

    async getExistingOrNewBlankAssessmentFor(claimActivityId: string): Promise<RepoClaimAssessment> {
        const claimActivity = await this.get(claimActivityId)
        return claimActivity.assessment ?? {
            hospitalAdmissions: [],
            invoiceLineItems: [],
            submissionReasons: [],
            linkedClaimActivityIds: [],
            requestedTreatments: []
        }
    }
}
